@import url(https://fonts.googleapis.com/css?family=Josefin+Sans);
/* home page css */

/* @import "~slick-carousel/slick/slick-theme.css";
@import "~slick-carousel/slick/slick.css"; */

/* Chrome, Safari, Edge, Opera */


*{
    font-family: sans-serif;
}



input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
a{
    text-decoration: none;
}
body {
    background-color: black;
    color: white;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
}
p{
    margin: 0;
    padding: 0;
}
h1 h2 h3 h4 h5 h6{
    margin: 0;
    padding: 0;
    -webkit-margin-before: 0em;
            margin-block-start: 0em;
    -webkit-margin-after: 0em;
            margin-block-end: 0em;
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px;
}
ul{
    margin: 0;
    padding: 0;
}

/* PC CSS */

@media only screen and (min-width: 800px) {
    .sidebar{
        display: none;
    }
    .kv-container{
        width: 100vw;
        height: 55vw;
        background-image: url(/static/media/home-kv-img.6b285835.jpg);
        background: linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url(/static/media/home-kv-img.6b285835.jpg);
        background-size: cover;
        padding: 0;
        margin: 0;
        margin-top: 20px;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 3em;
        border-bottom: 1px solid grey;
    }
    .kv-container b{
        color:#eb05a2;
    }
    .kv-container h1{
        font-size: 1em;
        font-weight: normal;
        font-family: 'Josefin Sans', cursive;
    }
    .kv-container h1:nth-of-type(2){
        padding-bottom: 5rem;
    }
    
    .kv-slider{
        margin: 0 auto;
        max-width: 900px;
        max-height: 800px;
        margin-top: 30px;
        margin-bottom: 50px;
    }
    
    .kv-slider img{
        height: 600px;
        max-width: 100%;
    }
    
    .bottom-info{
        display: flex;
    margin: 0 auto;
    justify-content: space-between;
    max-width: 900px;    
    }
    .bottom-info-box{
        display: flex;
        flex-direction: column;
    }
    .bottom-info-box h3{
        color: #eb05a2;
        padding: 0;
        margin: 0;
    }
    .bottom-info-box span{
        color: #c627e6;
        line-height: 1.5em;
        font-size: 16px;
    }
    .bottom-info-box p {
        color: #eb05a2;
        padding-top: 20px;
        font-size: 18px;
        font-weight: 700;
    }
    
    footer{
        display: flex;
        justify-content: space-between;
        max-width: 900px;
        margin-top: 100px;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 50px;
    
    }
    footer img{
        max-width: 200px;
        max-height: 70px;
        width: 100%;
        height: auto;
    }
    
    /* pricing page */
    .pricing-kv-container{
        display: flex;
        justify-content: left;
        width: 100vw;
        height: 40vw;
        background-image: url(/static/media/pricing-kv-img.418712a4.jpg);
        background: linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url(/static/media/pricing-kv-img.418712a4.jpg);
        background-size: cover;
        margin-top: 30px;
        margin-bottom: 60px;
        font-size: 8.48vw;
        color: #eb05a2;
        border-bottom: 1px solid grey;
    }
    .pricing-kv-container span{
    padding-left: 10vw;
    padding-bottom: 5.46vw;
      align-self: flex-end;
      white-space: pre-line;
    }
    
    .pricing-main-txt-container{
        max-width: 700px;
        margin: 0 auto;
        margin-bottom: 30px;
    }
    .pricing-kv-txt{
        color: #eb05a2;
        text-transform: uppercase;
        text-align: center;
    }
    .pricing-kv-txt h3{
        font-size: 2em;
        margin: 10px 0;
    }
    .pricing-main-txt-container h4{
        color: #c627e6;
        font-size: 2em;
        margin-bottom: 20px;
    }
    .pricing-main-txt-container p{
        color: white;
     font-size: 1.5em;
    }
    .pricing-txt-bottom{
        max-width: 900px;
        display: flex;
        margin: 0 auto;
        font-size: 1em;
        margin-top: 100px;
        display: flex;
        justify-content: space-between;
        padding: 0 2vw ;
    }
    .pricing-txt-bottom div{
        display: flex;
        justify-content: space-between;
    }
    .pricing-txt-bottom span{
        color: #eb05a2;
        font-size: 38px;
        word-break: break-word;
        max-width: 10ch;
    }
    .pricing-txt-bottom p{
        color: #c627e6;
        font-size: 19px;
        word-break: break-word;
        max-width: 74ch;
        display: flex;
        align-items: center;
        line-height: 2;
    }
    
    .party-kv-container{
        margin-top: 25px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid grey;
    }
    .party-kv-img{
        width: 50vw;
        height: 40vw;
        background-image: url(/static/media/party-kv-img.668cfd39.jpg);
        background-position: 25% 40%;
        background-size:cover;
        background-repeat: no-repeat;
        padding: 0;
        margin: 0;
    }
    .party-kv-container span{
        margin-top: 25px;
        display: flex;
        color: #eb05a2;
        font-size: 3.5em;
        word-break: break-word;
        max-width: 15ch;
        text-transform: uppercase;
        margin-left: 2.5rem;
    }
    .party-txt-header{
        text-align: center;
        font-size: 24px;
        max-width: 650px;
        margin: 40px auto;
    }
    .party-txt-header span{
        color: #c627e6;
    }
    .party-txt-header p{
        margin-top: 30px;
        margin-bottom: 8rem;
    }
    .party-txt{
        display: flex;
        justify-content: space-between;
        max-width: 900px;
        margin-top: 50px;
        margin-left: auto;
        margin-right: auto;
        padding: 0 2vw;
    }
    .party-txt ul{
        font-size: 18px;
        line-height: 30px;
    }
    .party-txt ul li:nth-of-type(5){
        margin-top: 25px;
    }
    .party-txt span{
        text-transform: uppercase;
        color: #c627e6;
        word-break: break-word;
        max-width: 10ch;
        font-size: 35px;
    }
    
    
    /* footer social media */
    .social-media-links{
        display: flex;
        justify-content: space-evenly;
        min-width: 125px;
    }
    .social-media-links div{
        background-color: white; 
        border-radius: 10%; 
        display: flex; 
        width:12px; 
        height: 14px; 
        justify-content: center; 
        align-items: center; 
        margin-top: 2px;
    }
    .social-media-links div svg{
        color: rgb(233, 89, 80);
        font-size: 20px;
    }
    
    
    /* drinks page css */
    .drinks-kv-container{
        margin-top: 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid grey;
        border-top: 1px solid grey;
    }
    .drinks-kv-container span{
        margin-top: 25px;
        display: flex;
        color: #eb05a2;
        font-size: 80px;
        word-break: break-word;
        max-width: 15ch;
        text-transform: uppercase;
        margin-left: 2.5rem;
    }
    .drinks-kv-img{
        width: 50vw;
        height: 40vw;
        background-image: url(/static/media/drinks-kv-img.8ce24367.jpg);
        background-position: 25% 40%;
        background-size:cover;
        background-repeat: no-repeat;
        padding: 0;
        margin: 0;
    }
    
    .drink-txt-box{
        margin: 0 auto;
        margin-top: 30px;
        display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-gap: 20px;
      max-width: 900px;
      text-align: center;
      border-bottom: rgb(102, 102, 102) 1px solid;
    }
    
    
    .drinks-txt span{
        color:#eb05a2;
        font-size: 31px;
        font-weight: 400;
        
    }
    .drinks-txt p{
        color: white;
        font-size: 19px;
        font-weight: 400;
        margin-top: 10px;
        margin-left: 5px;
    }
    
    /* events page css */
    .events-kv-container{
        margin-top: 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid grey;
        border-top: 1px solid grey;
    }
    .events-kv-container span{
        margin-top: 25px;
        display: flex;
        color: #eb05a2;
        font-size: 55px;
        word-break: break-word;
        max-width: 20ch;
        text-transform: uppercase;
        margin-left: 2.5rem;
        max-width: 427px;
    }
    .events-kv-img{
        width: 50vw;
        height: 40vw;
        background-image: url(/static/media/events-kv-img.2816e8d3.jpg);
        background-position: 50% 30%;
        background-size:cover;
        background-repeat: no-repeat;
        padding: 0;
        margin: 0;
    }
    .events-txt-box{
        margin: 0 auto;
        padding: 3vw;
        margin-top: 30px;
        display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr ;
      grid-gap: 20px;
      max-width: 900px;
      text-align: center;
      border-bottom: rgb(102, 102, 102) 1px solid;
    }
    
    
    .events-txt span{
        color:#c627e6;
        font-size: 31px;
        font-weight: 400;
        
    }
    .events-txt p{
        color: white;
        font-size: 19px;
        font-weight: 400;
        margin-top: 20px;
        margin-left: 5px;
    }
    .facebook-conent-container{
        display: flex;
        justify-content: center;
        align-items: center;
        /* width: 110vw; */
        margin: 0 auto;
    }
    
    .bottom-event-txt{
        margin: 0 auto;
        text-align: center;
        margin-top: 150px;
    }
    .bottom-event-txt h1{
        color: #eb05a2;
    }
    .bottom-event-txt h2{
        color:#c627e6;
        font-size: 19px;
        font-weight: 400;
    
    }
    
    /* about us page */
    .about-kv-container{
        margin-top: 25px;
        display: flex;
        align-items: center;
        width: 100%;
        
        border-bottom: 1px solid grey;
    }
    .about-title-container{
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }
    .about-title-container span{
        margin: 0 auto;
        font-size: 30vw;
    }
    .about-title-container span:nth-of-type(1){
        color: #c627e6;
        margin: 0 auto;
        
    
    }
    .about-title-container span:nth-of-type(2){
        color: #eb05a2;
        
        
    
    }
    .about-kv-container span{
        margin-top: 25px;
        display: flex;
        color: #eb05a2;
        font-size: 55px;
        text-transform: uppercase;
        margin-left: 2.5rem;
        
    }
    .about-kv-img{
        width: 50vw;
        height: 40vw;
        background-image: url(/static/media/about-kv-img.97820440.jpg);
        background-position: 50% 30%;
        background-size:cover;
        background-repeat: no-repeat;
        padding: 0;
        margin: 0;
    }
    
    .about-txt{
        display: flex;
        justify-content: space-evenly;
        margin: 30px auto;
        margin-top: 0;
        padding-top: 30px;
        padding-bottom: 30px;
        border-top: grey 1px solid;
        border-bottom: grey 1px solid;
    }
    .about-txt span{
        color: #c627e6;
        font-size: 40px;
        font-weight: 400;
        margin: 0px 0px;
        text-align: center;
    }
    .about-txt p{
        color: white;
        word-break: break-word;
        max-width: 60ch;
        font-size: 18px;
        font-weight: 400;
        padding: 10px 0px;
    }
    .about-txt-flex-column{
        display: flex;
        flex-direction: column;
    }
    .about-txt-flex-column p:nth-of-type(2){
        margin-top: 10px;
    }
    
    .about-txt-bottom-kv{
        display: flex;
        justify-content: space-between;
        max-width: 75vw;
        margin: 0 auto;
        margin-top: 100px;
        flex-direction: column-reverse;
    }
    .nelsons-story-container{
        display: flex;
        flex-direction: column;
        padding-top: 60px;
    }
    .nelsons-story-container span{
        color: #eb05a2;
        font-size: 31px;
    }
    .nelsons-story-container p{
        margin: 20px 0;
        word-break: break-word;
        max-width: 45ch;
        font-size: 19px;
        line-height: 27px;
    }
    .about-bottom-txt-kv-img-container{
        width: 50vw;
        height: 53vw;
        background-image: url(/static/media/about-bottom-kv-img.ab5f0284.jpg);
        background-position: 50% 30%;
        background-size:cover;
        background-repeat: no-repeat;
        padding: 0;
        margin: 0;
    }
    
    /* Gift Card Page */
    
    .gift-cards-container{
        background-color: white;
        color: black;
        margin: 0;
        padding: 0;
        width: 100vw;
        height: auto;
    }
    
    .gift-cards-container nav{
        max-width: 100vw;
        text-align: center;
    
       
        position: -webkit-sticky;
    
       
        position: sticky;
        top: 0;
        width: 100%;
        background-color: white;
        z-index: 999;
    
        padding-top: 20px;
        border-bottom: 2px solid grey;
        padding-bottom: 30px;
    }
    .item-1 {
        /* grid-row-start: 1;
        grid-row-end: 3; */
        grid-column-start: 2;
        grid-column-end: 3;
      }
    .gift-cards-container nav a p:first-child{
        margin-top: 15px;
    }
    .gift-cards-container nav span{
        color: white;
    }
    
    .gift-cards-container nav img{
        max-width: 75px;
        max-height: 30px ;
    }
    
    .gift-cards-container .background-color-container{
        background-color: #556b7c;
        color: white;
        margin-bottom: 5rem;
    }
    
    .background-color-container{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .background-color-container span{
        font-size: 36px;
        font-weight: 200;
        margin-top: 30px;
       }
    .background-color-container p{
        font-size: 18px;
        margin-top: 1rem;
    }
    .gift-card-img-container{
        max-width: 320px;
        width: 100%;
        height: 200px;
        border: 5px solid white;
        border-radius: 3%;
        box-shadow: 0 4px 2px -2px rgb(177, 177, 177);
        background-color: white;
        background-image: url(/static/media/gift-card-kv-img.9ba3bd0c.png);
        background-position: 50% 30%;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 0;
        margin: 0;
        position: relative;
        top: 40px;
    }
    /* form css */
    .payment-container{
        max-width: 450px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: 0 auto;
    }
    .payment-container label{
        font-size: 13px;
        font-weight: bold;
        letter-spacing: .65px;
        color: #43494f;
        margin-top: 20px;
        margin-bottom: 10px;
    }
    .payment-container .price-buttons{
        font-size: 15px;
        font-stretch: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }
    .price-buttons{
        color: #64696E;
    }
    .payment-container .price-buttons span{
        padding-left: auto;
        padding-right: auto;
        font-weight: bold;
        width: 100%;
    }
    
    .payment-container .price-buttons span:nth-child(5){
        border-right: none;
    }
    .list-items{
        display: flex;
        /* justify-content: space-between; */
        align-items:center;
        border: 1px solid #d5d9dc;
        border-radius: 3px;
    }
    .list-items b{
        padding-left: 15px;
        padding-right: 15px;
    }
    .list-items div{
        height: 25px;
        border-right: 1px rgb(213, 217, 220) solid;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 10px;
    }
    .list-items div:nth-of-type(5){
        width: 100%;
        /* padding-left: 35px; */
        /* border-right: none; */
    }
    .custom-button{
        border: 1px solid rgb(213, 217, 220);
        border-radius: 3px;
        height: 25px;
        border-right: 1px rgb(213, 217, 220) solid;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 10px;
    }
    
    /* conditional class on price buttons */
    .item-list{
        background-color: white;
    }
    .price-selected{
        color: white;
        background-color: black;
    }
    .payment-container input{
        margin-top: 5px;
        height: 30px;
        border: 1px rgb(213, 217, 220) solid;
        margin-bottom: 5px;
    }
    .inputWithButtton{
        position: relative;
        height: 100px;
    }
    .promo-code-input{
        padding-right: 30px;
    }
    .promo-code-button{
        background-color: white;
        border: none;
        color: #2996cc;
        width: 12%;
        margin-left: auto;
        margin-right: 5px;
        position: relative;
        bottom: 31px
    }
    .promo-code-button:hover{
        cursor: pointer;
    }
    .user-form-flexbox{
        display: flex;
        justify-content: space-between;
    }
    .user-form-flexbox article{
        display: flex;
        
    }
    .user-form-flexbox article input{
        width: 96%;
        height: 30px;
    }
    .user-form-flexbox .error-message{
        color: red;
        font-size: 14px;
    }
    .checkbox-container{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-right: auto;
        -webkit-margin-before: 0px;
                margin-block-start: 0px;
        -webkit-margin-after: 0px;
                margin-block-end: 0px;
        -webkit-margin-start: 0px;
                margin-inline-start: 0px;
        -webkit-margin-end: 0px;
                margin-inline-end: 0px;
        margin-top: 10px;
    }
    .checkbox-container input {
        height: 24px;
        width: 24px;
    }
    .checkbox-container span {
        line-height: 20px;
    }
    
    .recipient-form-flexbox{
        display: flex;
        justify-content: space-between;
    }
    .recipient-form-flexbox aside{
        width: 49%;
        
    }
    .recipient-form-flexbox aside input{
        width: 96%;
        height: 30px;
    }
    
    .payment-container textarea {
        border: 1px rgb(195, 198, 201) solid;
    }
    .payment-container .personal-msg-txt u{
        color: rgb(195, 198, 201);
        text-decoration: none;
    }
    
    .delivery-buttons-flexbox{
        display: flex;
        align-items: center;
    }
    .delivery-buttons-flexbox aside{
        width: 50%;
        text-align: center;
        
    }
    .delivery-buttons-flexbox aside div{
        height: 30px;
        padding-top: 15px;
        border-radius: 2px;
        border: 1px solid rgb(213, 217, 220);
        font-size: 14px;
        font-weight: bold;
    }
    .delivery-buttons-flexbox aside div:hover{
        cursor: pointer;
    }
    .delivery-buttons-flexbox .delivery-active{
        background-color: #32373c;
        color: white;
    }
    .delivery-buttons-flexbox .delivery{
        color: #32373c !important;
        background-color: white !important;
    }
    .calendar{
        display: flex;
        justify-content: center;
        margin-top: 1vw;
    }
    .checkout-button{
        width: 100%;
        height: 30px;
        padding-top: 15px;
        padding-bottom: 30px;
        margin-top: 15px;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 1vw;
        text-align: center;
        background-color: #006edce6;
        color: white;
        font-weight: bolder;
        font-size: 16px;
    }
    
    .checkout-button:hover{
        cursor: pointer;
    }
    
    /* checkoutpage css */
    .checkout-container{
        background-color: white !important;
        color: black;
        margin: 0;
        padding: 0;
        max-width: 1920px;
        height: auto;
    }
    
    .checkout-container nav{
        max-width: 100vw;
    
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        grid-gap: 5px;
        text-align: center;
    
       
        position: -webkit-sticky;
    
       
        position: sticky;
        top: 0;
        width: 100%;
        background-color: white;
        z-index: 999;
    
        padding-top: 20px;
        border-bottom: 2px solid grey;
        padding-bottom: 30px;
    }
    .item-1 {
        /* grid-row-start: 1;
        grid-row-end: 3; */
        grid-column-start: 2;
        grid-column-end: 3;
      }
    .checkout-container nav span{
        color: white;
    }
    
    .checkout-container nav img{
        max-width: 75px;
        max-height: 30px ;
    }
    
    
    .checkout-container .checkout-title h1, h2{
        text-align: center;
        margin-bottom: 10px;
    }
    .checkout-container .checkout-title h1{
        font-size: 36px;
        font-weight: normal;
        color: #43494f;
    }
    .checkout-container .checkout-title h2{
        font-size: 18px;
        font-weight: normal;
        color: #43494f;
    }
    
    .checkout-content-container{
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        margin-top: 50px;
        padding-bottom: 50px;
    }
    
    .checkout-content-container .stripe-js-content-container{
    margin-right: 30px;
    margin-top: 25px;
    max-width: 500px;
    }
    
    .digital-wallet-divder {
        text-align: center; 
        }
        
        .divider-line {
            height: 1px;
            background: rgb(189, 189, 189);
            max-width: 500px;
        }
        
        .digital-wallet-divder  h2 {
            position: relative;
            bottom: 34px;
            background: #fff;
            display: inline-block;
            padding: 0 20px;
           
        }
    
        .payment-inputs__container .jnYdMq{
            width: 100%;
            margin-bottom: 30px;
        }
        .payment-inputs__container-error .jnYdMq{
            border: 2px solid red;
            border-radius: 5px;
        }
        .payment-warning p{
            margin-bottom: 30px;
            line-height: 24px;
        }
        .payment-warning b {
            color: #2996cc;
            font-weight: normal;
        }
        .payment-warning b:hover{
            cursor: pointer;
        }
    .payment-submit-button{
            background-color: #006aff;
            margin-top: 20px;
            margin-bottom: 20px;
            color: white;
            width: 100%;
            padding-top: 20px;
            padding-bottom: 20px;
            font-weight: bolder;
            font-size: 18px;
            border: none;
            border-radius: 5px;
    }
    .payment-submit-button:hover {
            cursor: pointer;
        }
    
    /* order details  */
    .order-details__container{
       height: auto;
    }
    .order-details__container .order-details__top-content{
        display: flex;
        justify-content: space-between;
        min-width: 350px;
    }
    .order-details__container .order-details__top-content b{
        font-weight: normal;
        color: #006aff;
    }
    .order-details__container .order-details__top-content b:hover{
        cursor: pointer;
    }
    .order-details__container .order-details__top-content span{
        font-weight: 500;
        letter-spacing: 0.05em;
    }
     .gift-card-payment-img-container {
        max-width: 300px;
        width: 100%;
        height: 200px;
        background-color: white;
        background-image: url(/static/media/gift-card-kv-img.9ba3bd0c.png);
        background-position: 50% 30%;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 0;
        margin: 0 auto;
        
    }
    .order-details__container .order-details__top-element-border{
        width: 100%;
        max-width: 300px;
        padding: 24px;
        border: rgb(190, 190, 190) 1px solid;
        border-radius: 3px;
    }
    .order-details__container .order-details__content{
        width: 100%;
        border: rgb(190, 190, 190) 1px solid;
        border-top: none;
        border-radius: 3px;
        max-width: 300px;
        padding: 24px;
        padding-top: 10px;
    }
    .order-details__container .price-date__flex{
        display: flex;
        justify-content: space-between;
    }
    .order-details__container .price-date__flex h1{
        font-size: 36px;
        padding: 0;
        margin: 0;
        color: #43494f;
        margin-top: 10px;
    }
    .order-details__container .price-date__flex h2{
        font-size: 18px;
        padding: 0;
        color: #777c81;
        
    }
    
    .order-details__container .amount-pay__container{
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: #006aff;
        padding-bottom: 10px;
        border-bottom: 6px solid #f8f4f4;
        margin-bottom: 20px;
    }
    .order-details__container .amount-pay__container h3, h4{
        margin-bottom: 0;
        padding: 0;
    }
    .order-details__container .amount-pay__container h4{
        font-size: 20px;
        margin-top: 17px;
    }
    .order-details__container .recipient-credentials{
        margin-bottom: 15px;
    }
    .order-details__container .recipient-credentials p:nth-child(1){
        margin-bottom: 10px;
        font-weight: 550;
        color: #777c81;
        line-height: 20px;
    }
    .order-details__container .recipient-credentials p:nth-child(2){
        color: #777c81;
        font-weight: 300;
        
    }
    
    .order-details__container .location-tag{
        word-wrap: break-word;
        font-weight: 550;
        color: #777c81;
        text-align: left;
        width: 32ch;
        line-height: 26px;
    }
    
    /* auto email css */
    .auto-email-form{
        display: flex;
        flex-direction: column;
    }
    .auto-email-form label, input{
        margin-bottom: 10px;
    }
    .auto-email-form input{
        min-height: 25px;
        border-radius: 5px;
        border: rgb(190, 190, 190) 1px solid;
    }
    .auto-email-form textarea{
        border-radius: 5px;
        border: rgb(190, 190, 190) 1px solid;
    }
    .auto-email-form .hidden-input{
        visibility: hidden;
        outline: none;
        border: none;
    }    
}

@media only screen and (min-width: 600px) and (max-width: 799px) {
    
    body{
        /* padding: 2vw; */
        margin: 0px;
    }
    .sidebar h1{
       color: white;
       font-size: 2.8vw;
       min-width: 150px;
       text-align: center;
    }
    .sidebar ul {
        list-style: none;
    }
    .sidebar a {
        color: black !important;
        display: block;
        font-size: 2vw;
    }

    .kv-container{
        width: 100vw;
        height: 85vw;
        background-image: url(/static/media/home-kv-img.6b285835.jpg);
        background: linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url(/static/media/home-kv-img.6b285835.jpg);
        background-size: cover;
        padding: 0;
        margin: 0;
        margin-top: 20px;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 3em;
        border-bottom: 1px solid grey;
    }
    .kv-container b{
        color:#eb05a2;
    }
    .kv-container h1{
        font-size: 4.5vw;
        font-weight: normal;
        font-family: 'Josefin Sans', cursive;
    }
    .kv-container h1:nth-of-type(2){
        padding-bottom: 5rem;
    }
    
    .kv-slider{
        margin: 0 auto;
        max-width: 95vw;
        max-height: 80vw;
        margin-top: 30px;
        margin-bottom: 50px;
    }
    
    .kv-slider img{
        max-height: 80vw;
        max-width: 100%;
        min-height: 80vw;
    }
    .slick-dots li button:before{
        color: white ;
        
    }

    
    .bottom-info{
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        justify-content: space-between;
        max-width: 80vw;    
    }
    .bottom-info-box{
        display: flex;
        flex-direction: column;
    }
    .bottom-info-box h3{
        color: #eb05a2;
        padding: 0;
        margin: 0;
        font-size: 2.9vw;
        margin-bottom: 3vw;
        margin-top: 22vw;
    }
    .bottom-info-box span{
        color: #c627e6;
        line-height: 1.5em;
        font-size: 2.95vw;
    }
    .bottom-info-box p {
        color: #eb05a2;
        padding-top: 20px;
        margin-bottom: 3vw;
        font-size: 2.9vw;
        font-weight: 700;
    }
    
    footer{
        display: flex;
        justify-content: space-between;
        max-width: 900px;
        margin-top: 0vw;
        padding: 2vw;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 3.9vw;
        font-size: 3vw;
    
    }
    footer img{
        max-width: 29vw;
        max-height: 70px;
        width: 100%;
        height: auto;
    }
    
    /* pricing page */
    .pricing-kv-container{
        display: flex;
        justify-content: left;
        width: 100vw;
        height: 50vw;
        background-image: url(/static/media/pricing-kv-img.418712a4.jpg);
        background: linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url(/static/media/pricing-kv-img.418712a4.jpg);
        background-size: cover;
        margin-top: 30px;
        margin-bottom: 60px;
        font-size: 11vw;
        color: #eb05a2;
        border-bottom: 1px solid grey;
    }
    .pricing-kv-container span{
      padding-left: 10vw;
      padding-bottom: 5.46vw;
      align-self: flex-end;
      white-space: pre-line;
    }
    
    .pricing-main-txt-container{
        max-width: 700px;
        margin: 0 auto;
        margin-bottom: 30px;
        padding: 5vw;
        padding-top: 0;
    }
    .pricing-kv-txt{
        color: #eb05a2;
        text-transform: uppercase;
        text-align: center;
    }
    .pricing-kv-txt h3{
        font-size: 4vw;
        margin: 10px 0;
    }
    .pricing-main-txt-container h4{
        color: #c627e6;
        font-size: 4vw;
        margin-bottom: 20px;
    }
    .pricing-main-txt-container p{
        color: white;
         font-size: 3vw;
    }
    .pricing-txt-bottom{
        max-width: 900px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0 auto;
        font-size: 1em;
        margin-top: 7.5vw;
        padding: 0 9vw;
    
    }
    .pricing-txt-bottom div{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .pricing-txt-bottom span{
        color: #eb05a2;
        font-size: 5.8vw;
        margin-bottom: 3vw;
    }
    .pricing-txt-bottom p{
        color: #c627e6;
        font-size: 4vw;
        text-align: left;
        line-height: 1.5;
    }
    
    .party-kv-container{
        margin-top: 1vw;
        display: flex;
        align-items: center;
        border-bottom: 1px solid grey;
    }
    .party-kv-img{
        width: 50vw;
        height: 40vw;
        background-image: url(/static/media/party-kv-img.668cfd39.jpg);
        background-position: 25% 40%;
        background-size:cover;
        background-repeat: no-repeat;
        padding: 0;
        margin: 0;
    }
    .party-kv-container span{
        
        display: flex;
        color: #eb05a2;
        font-size: 7vw;
        word-break: break-word;
        max-width: 15ch;
        text-transform: uppercase;
        margin-left: 1.75vw;
    }
    .party-txt-header{
        text-align: center;
        font-size: 5vw;
        max-width: 650px;
        margin: 40px auto;
        padding: 0 1.5vw;
    }
    .party-txt-header span{
        color: #c627e6;
    }
    .party-txt-header p{
        margin-top: 30px;
        margin-bottom: 4vw;
    }
    .party-txt{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        max-width: 900px;
        margin-top: 50px;
        padding: 0 4vw;
    }
    .party-txt ul{
        font-size: 3vw;
        line-height: 30px;
        padding-left: 2vw;
        padding-top: 2vw;
    }
    /* .party-txt ul li:nth-of-type(5){
        margin-top: 25px;
    } */
    .party-txt span{
        text-transform: uppercase;
        color: #c627e6;
        word-break: break-word;
        max-width: 10ch;
        font-size: 6vw;
    }
    
    
    /* footer social media */
    .social-media-links{
        display: flex;
        justify-content: space-evenly;
        min-width: 125px;
       
    }
    .social-media-links div{
        background-color: white; 
        border-radius: 10%; 
        display: flex; 
        width:12px; 
        height: 14px; 
        justify-content: center; 
        align-items: center; 
        margin-top: 2px;
    }
    .social-media-links div svg{
        color: rgb(233, 89, 80);
        font-size: 20px;
    }
    
    
    /* drinks page css */
    .drinks-kv-container{
        margin-top: 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid grey;
        border-top: 1px solid grey;
    }
    .drinks-kv-container span{
        margin-top: 25px;
        display: flex;
        color: #eb05a2;
        font-size: 9vw;
        word-break: break-word;
        max-width: 15ch;
        text-transform: uppercase;
        margin-left: 3vw;
        max-width: 300px;
    }
    .drinks-kv-img{
        width: 75vw;
        height: 40vw;
        background-image: url(/static/media/drinks-kv-img.8ce24367.jpg);
        background-position: 25% 40%;
        background-size:cover;
        background-repeat: no-repeat;
        padding: 0;
        margin: 0;
    }
    
    .drink-txt-box{
        margin: 0 auto;
        margin-top: 30px;
        display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-gap: 20px;
      max-width: 900px;
      text-align: center;
      border-bottom: rgb(102, 102, 102) 1px solid;
    }
    
    
    .drinks-txt span{
        color:#eb05a2;
        font-size: 31px;
        font-weight: 400;
        
    }
    .drinks-txt p{
        color: white;
        font-size: 19px;
        font-weight: 400;
        margin-top: 10px;
        margin-left: 5px;
    }
    
    /* events page css */
    .events-kv-container{
        margin-top: 25px;
        min-height: 60vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-bottom: grey 1px solid;
        border-top: grey 1px solid;
    }
    .events-kv-container span{
        margin-top: 25px;
        max-width: 215px;
        line-height: 1.3;
        display: flex;
        color: #eb05a2;
        font-size: 48px;
         word-break: break-word;
        /* max-width: 20ch;  */
        text-transform: uppercase;
        margin-left: 2.5vw;
    }
    .events-kv-img{
        width: 50vw;
        height: 60vw;
        background-image: url(/static/media/events-kv-img.2816e8d3.jpg);
        background-position: 50% 30%;
        background-size:cover;
        background-repeat: no-repeat;
        padding: 0;
        margin: 0;
    }
    .events-txt-box{
        margin: 0 auto;
        margin-top: 30px;
        display: grid;
        /* grid-template-columns: 1fr 1fr 1fr; */
        grid-template-rows: 1fr 1fr ;
        grid-gap: 10px;
        max-width: 900px;
        text-align: center;
        border-bottom: rgb(102, 102, 102) 1px solid;
    }
    .events-txt{
        margin-bottom: 30px;
        padding: 10px;
    }
    
    .events-txt span{
        color:#c627e6;
        font-size: 31px;
        font-weight: 400;
        
    }
    .events-txt p{
        color: white;
        font-size: 19px;
        font-weight: 400;
        margin-top: 20px;
        margin-left: 5px;
    }
    .facebook-conent-container{
        display: flex;
        justify-content: center;
        /* align-items: center; */
        width: 110vw;
        /* margin: 0 auto; */
    }
    
    .bottom-event-txt{
        margin: 0 auto;
        text-align: center;
        margin-top: 50px;
        margin-bottom: 20px;
    }
    .bottom-event-txt h1{
        color: #eb05a2;
        font-size: 20px;
        max-width: 400px;
        margin: 0 auto;
        margin-bottom: 20px;
        /* word-break: break-word;
        max-width: 10ch; */
        
    }
    .bottom-event-txt h2{
        color:#c627e6;
        font-size: 18px;
        font-weight: 400;
        max-width: 400px;
        margin: 0 auto;
    
    }
    
    /* about us page */
    .about-kv-container{
        margin-top: 25px;
        display: flex;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid grey;
    
    }
    .about-title-container{
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }

    .about-title-container span:nth-of-type(1){
        color: #c627e6;
        margin: 0 auto;
        font-size: 7vw;
    }
    .about-title-container span:nth-of-type(2){
        color: #eb05a2;
        margin: 0 auto;
        font-size: 7vw;
    
    }
    .about-kv-container span{
        margin-top: 25px;
        display: flex;
        color: #eb05a2;
        font-size: 55px;
        text-transform: uppercase;
        margin-left: 2.5rem;
        
    }
    .about-kv-img{
        width: 50vw;
        height: 40vw;
        background-image: url(/static/media/about-kv-img.97820440.jpg);
        background-position: 50% 30%;
        background-size:cover;
        background-repeat: no-repeat;
        padding: 0;
        margin: 0;

    }
    
    .about-txt{
        display: flex;
        justify-content: space-evenly;
        max-width: 900px;
        margin: 30px auto;
        margin-top: 0;
        padding-top: 30px;
        padding-bottom: 30px;
        border-top: grey 1px solid;
        border-bottom: grey 1px solid;
    }
    .about-txt span{
        color: #c627e6;
        font-size: 7vw;
        font-weight: 400;
        margin: 0px 0px;
        text-align: center;
    }
    .about-txt p{
        color: white;
        word-break: break-word;
        max-width: 60ch;
        font-size: 19px;
        font-weight: 400;
        padding: 10px 0px;
    }
    .about-txt-flex-column{
        display: flex;
        flex-direction: column;
        font-size: 4vw;
    }
    .about-txt-flex-column p{
        font-size: 2.8vw;
        word-break: break-all;
        width: 26ch;
        line-height: 1.5;
    }
    .about-txt-flex-column p:nth-of-type(2){
        margin-top: 10px;
    }
    
    .about-txt-bottom-kv{
        display: flex;
        justify-content: space-between;
        flex-direction: column-reverse;
        max-width: 75vw;
        margin: 0 auto;
        margin-top: 150px;
    }
    .nelsons-story-container{
        display: flex;
        flex-direction: column;
        padding-top: 60px;
    }
    .nelsons-story-container span{
        color: #eb05a2;
        font-size: 31px;
    }
    .nelsons-story-container p{
        margin: 20px 0;
        word-break: break-word;
        max-width: 45ch;
        font-size: 19px;
        line-height: 27px;
    }
    .about-bottom-txt-kv-img-container{
        width: 50vw;
        height: 55vw;
        background-image: url(/static/media/about-bottom-kv-img.ab5f0284.jpg);
        background-position: 50% 30%;
        background-size:cover;
        background-repeat: no-repeat;
        padding: 0;
        margin: 0;
    }
    
    /* Gift Card Page */
    
    .gift-cards-container{
        background-color: white;
        color: black;
        margin: 0;
        padding: 0;
        width: 100vw;
        height: auto;
    }
    
    .gift-cards-container nav{
        max-width: 100vw;
        text-align: center;
    
       
        position: -webkit-sticky;
    
       
        position: sticky;
        top: 0;
        width: 100%;
        background-color: white;
        z-index: 999;
    
        padding-top: 20px;
        border-bottom: 2px solid grey;
        padding-bottom: 30px;
    }
    .item-1 {
        /* grid-row-start: 1;
        grid-row-end: 3; */
        grid-column-start: 2;
        grid-column-end: 3;
      }
      
    .gift-cards-container nav span{
        color: white;
    }
    .gift-cards-container nav a p:first-child{
        margin-top: 15px;
    }
    .gift-cards-container nav img{
        max-width: 75px;
        max-height: 30px ;
    }
    
    .gift-cards-container .background-color-container{
        background-color: #556b7c;
        color: white;
        margin-bottom: 5rem;
    }
    
    .background-color-container{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .background-color-container span{
        font-size: 36px;
        font-weight: 200;
        margin-top: 30px;
       }
    .background-color-container p{
        font-size: 18px;
        margin-top: 1rem;
    }
    .gift-card-img-container{
        max-width: 320px;
        width: 100%;
        height: 200px;
        border: 5px solid white;
        border-radius: 3%;
        box-shadow: 0 4px 2px -2px rgb(177, 177, 177);
        background-color: white;
        background-image: url(/static/media/gift-card-kv-img.9ba3bd0c.png);
        background-position: 50% 30%;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 0;
        margin: 0;
        position: relative;
        top: 40px;
    }
    /* form css */
    .payment-container{
        max-width: 450px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: 0 auto;
    }
    .payment-container label{
        font-size: 13px;
        font-weight: bold;
        letter-spacing: .65px;
        color: #43494f;
        margin-top: 20px;
        margin-bottom: 10px;
    }
    .payment-container .price-buttons{
        font-size: 15px;
        font-stretch: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }
    .price-buttons{
        color: #64696E;
    }
    .payment-container .price-buttons span{
        padding-left: auto;
        padding-right: auto;
        font-weight: bold;
        width: 100%;
    }
    
    .payment-container .price-buttons span:nth-child(5){
        border-right: none;
    }
    .list-items{
        display: flex;
        /* justify-content: space-between; */
        align-items:center;
        border: 1px solid #d5d9dc;
        border-radius: 3px;
    }
    .list-items b{
        padding-left: 15px;
        padding-right: 15px;
    }
    .list-items div{
        height: 25px;
        border-right: 1px rgb(213, 217, 220) solid;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 10px;
    }
    .list-items div:nth-of-type(5){
        width: 100%;
        /* padding-left: 35px; */
        
    }
    .custom-button{
        border: 1px solid rgb(213, 217, 220);
        border-radius: 3px;
        height: 25px;
        border-right: 1px rgb(213, 217, 220) solid;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 10px;
    }
    
    /* conditional class on price buttons */
    .item-list{
        background-color: white;
    }
    .price-selected{
        color: white;
        background-color: black;
    }
    .payment-container input{
        margin-top: 5px;
        height: 30px;
        border: 1px rgb(213, 217, 220) solid;
        margin-bottom: 5px;
    }
    .inputWithButtton{
        position: relative;
        height: 100px;
    }
    .promo-code-input{
        padding-right: 30px;
    }
    .promo-code-button{
        background-color: white;
        border: none;
        color: #2996cc;
        width: 12%;
        margin-left: auto;
        margin-right: 5px;
        position: relative;
        bottom: 31px
    }
    .promo-code-button:hover{
        cursor: pointer;
    }
    .user-form-flexbox{
        display: flex;
        justify-content: space-between;
    }
    .user-form-flexbox article{
        display: flex;
        
    }
    .user-form-flexbox article input{
        width: 96%;
        height: 30px;
    }
    .user-form-flexbox .error-message{
        color: red;
        font-size: 14px;
    }
    .checkbox-container{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-right: auto;
        -webkit-margin-before: 0px;
                margin-block-start: 0px;
        -webkit-margin-after: 0px;
                margin-block-end: 0px;
        -webkit-margin-start: 0px;
                margin-inline-start: 0px;
        -webkit-margin-end: 0px;
                margin-inline-end: 0px;
        margin-top: 10px;
    }
    .checkbox-container input {
        height: 24px;
        width: 24px;
    }
    .checkbox-container span {
        line-height: 20px;
    }
    
    .recipient-form-flexbox{
        display: flex;
        justify-content: space-between;
    }
    .recipient-form-flexbox aside{
        width: 49%;
        
    }
    .recipient-form-flexbox aside input{
        width: 96%;
        height: 30px;
    }
    
    .payment-container textarea {
        border: 1px rgb(195, 198, 201) solid;
    }
    .payment-container .personal-msg-txt u{
        color: rgb(195, 198, 201);
        text-decoration: none;
    }
    
    .delivery-buttons-flexbox{
        display: flex;
        align-items: center;
    }
    .delivery-buttons-flexbox aside{
        width: 50%;
        text-align: center;
        
    }
    .delivery-buttons-flexbox aside div{
        height: 30px;
        padding-top: 15px;
        border-radius: 2px;
        border: 1px solid rgb(213, 217, 220);
        font-size: 14px;
        font-weight: bold;
    }
    .delivery-buttons-flexbox aside div:hover{
        cursor: pointer;
    }
    .delivery-buttons-flexbox .delivery-active{
        background-color: #32373c;
        color: white;
    }
    .delivery-buttons-flexbox .delivery{
        color: #32373c !important;
        background-color: white !important;
    }
    .calendar{
        display: flex;
        justify-content: center;
        margin-top: 1vw;
    }
    .checkout-button{
        width: 100%;
        height: 30px;
        padding-top: 15px;
        padding-bottom: 30px;
        margin-top: 15px;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 1vw;
        text-align: center;
        background-color: #006edce6;
        border: none;
        color: white;
        font-weight: bolder;
        font-size: 16px;
    }
    
    .checkout-button:hover{
        cursor: pointer;
    }
    
    /* checkoutpage css */
    .checkout-container{
        background-color: white !important;
        color: black;
        margin: 0;
        padding: 0;
        max-width: 1920px;
        height: auto;
    }
    
    .checkout-container nav{
        max-width: 100vw;
    
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        grid-gap: 5px;
        text-align: center;
    
       
        position: -webkit-sticky;
    
       
        position: sticky;
        top: 0;
        width: 100%;
        background-color: white;
        z-index: 999;
    
        padding-top: 20px;
        border-bottom: 2px solid grey;
        padding-bottom: 30px;
    }
    .item-1 {
        /* grid-row-start: 1;
        grid-row-end: 3; */
        grid-column-start: 2;
        grid-column-end: 3;
      }
    .checkout-container nav span{
        color: white;
    }
    
    .checkout-container nav img{
        max-width: 75px;
        max-height: 30px ;
    }
    
    
    .checkout-container .checkout-title h1, h2{
        text-align: center;
        margin-bottom: 10px;
    }
    .checkout-container .checkout-title h1{
        font-size: 36px;
        font-weight: normal;
        color: #43494f;
    }
    .checkout-container .checkout-title h2{
        font-size: 18px;
        font-weight: normal;
        color: #43494f;
    }
    
    .checkout-content-container{
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-top: 50px;
        padding-bottom: 50px;
    }
    
    .checkout-content-container .stripe-js-content-container{
    margin-right: 30px;
    margin-top: 25px;
    max-width: 500px;
    }
    
    .digital-wallet-divder {
        text-align: center; 
        }
        
        .divider-line {
            height: 1px;
            background: rgb(189, 189, 189);
            max-width: 500px;
        }
        
        .digital-wallet-divder  h2 {
            position: relative;
            bottom: 34px;
            background: #fff;
            display: inline-block;
            padding: 0 20px;
           
        }
    
        .payment-inputs__container .jnYdMq{
            width: 100%;
            margin-bottom: 30px;
        }
        .payment-inputs__container-error .jnYdMq{
            border: 2px solid red;
            border-radius: 5px;
        }
        .payment-warning p{
            margin-bottom: 30px;
            line-height: 24px;
        }
        .payment-warning b {
            color: #2996cc;
            font-weight: normal;
        }
        .payment-warning b:hover{
            cursor: pointer;
        }
    .payment-submit-button{
            background-color: #006aff;
            margin-top: 20px;
            margin-bottom: 20px;
            color: white;
            width: 100%;
            padding-top: 20px;
            padding-bottom: 20px;
            font-weight: bolder;
            font-size: 18px;
            border: none;
            border-radius: 5px;
    }
    .payment-submit-button:hover {
            cursor: pointer;
        }
    
    /* order details  */
    .order-details__container{
       height: auto;
    }
    .order-details__container .order-details__top-content{
        display: flex;
        justify-content: space-between;
        min-width: 350px;
    }
    .order-details__container .order-details__top-content b{
        font-weight: normal;
        color: #006aff;
    }
    .order-details__container .order-details__top-content b:hover{
        cursor: pointer;
    }
    .order-details__container .order-details__top-content span{
        font-weight: 500;
        letter-spacing: 0.05em;
    }
     .gift-card-payment-img-container {
        max-width: 300px;
        width: 100%;
        height: 200px;
        background-color: white;
        background-image: url(/static/media/gift-card-kv-img.9ba3bd0c.png);
        background-position: 50% 30%;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 0;
        margin: 0 auto;
        
    }
    .order-details__container .order-details__top-element-border{
        width: 100%;
        max-width: 300px;
        padding: 24px;
        border: rgb(190, 190, 190) 1px solid;
        border-radius: 3px;
    }
    .order-details__container .order-details__content{
        width: 100%;
        border: rgb(190, 190, 190) 1px solid;
        border-top: none;
        border-radius: 3px;
        max-width: 300px;
        padding: 24px;
        padding-top: 10px;
    }
    .order-details__container .price-date__flex{
        display: flex;
        justify-content: space-between;
    }
    .order-details__container .price-date__flex h1{
        font-size: 36px;
        padding: 0;
        margin: 0;
        color: #43494f;
        margin-top: 10px;
    }
    .order-details__container .price-date__flex h2{
        font-size: 18px;
        padding: 0;
        color: #777c81;
        
    }
    
    .order-details__container .amount-pay__container{
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: #006aff;
        padding-bottom: 10px;
        border-bottom: 6px solid #f8f4f4;
        margin-bottom: 20px;
    }
    .order-details__container .amount-pay__container h3, h4{
        margin-bottom: 0;
        padding: 0;
    }
    .order-details__container .amount-pay__container h4{
        font-size: 20px;
        margin-top: 17px;
    }
    .order-details__container .recipient-credentials{
        margin-bottom: 15px;
    }
    .order-details__container .recipient-credentials p:nth-child(1){
        margin-bottom: 10px;
        font-weight: 550;
        color: #777c81;
        line-height: 20px;
    }
    .order-details__container .recipient-credentials p:nth-child(2){
        color: #777c81;
        font-weight: 300;
        
    }
    
    .order-details__container .location-tag{
        word-wrap: break-word;
        font-weight: 550;
        color: #777c81;
        text-align: left;
        width: 32ch;
        line-height: 26px;
    }
    
    /* auto email css */
    .auto-email-form{
        display: flex;
        flex-direction: column;
    }
    .auto-email-form label, input{
        margin-bottom: 10px;
    }
    .auto-email-form input{
        min-height: 25px;
        border-radius: 5px;
        border: rgb(190, 190, 190) 1px solid;
    }
    .auto-email-form textarea{
        border-radius: 5px;
        border: rgb(190, 190, 190) 1px solid;
    }
    .auto-email-form .hidden-input{
        visibility: hidden;
        outline: none;
        border: none;
    }    
  }

  @media only screen and (max-width: 599px){
    body{
        /* padding: 2vw; */
        margin: 0px;
    }
    .sidebar h1{
       color: white;
       font-size: 5.8vw;
       min-width: 150px;
       text-align: center;
    }
    .sidebar ul {
        list-style: none;
    }
    .sidebar a {
        color: black !important;
        display: block;
        font-size: 2vw;
    }

    .kv-container{
        width: 100vw;
        height: 85vw;
        background-image: url(/static/media/home-kv-img.6b285835.jpg);
        background: linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url(/static/media/home-kv-img.6b285835.jpg);
        background-size: cover;
        padding: 0;
        margin: 0;
        margin-top: 20px;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 3em;
        border-bottom: 1px solid grey;
    }
    .kv-container b{
        color:#eb05a2;
    }
    .kv-container h1{
        font-size: 4.5vw;
        font-weight: normal;
        font-family: 'Josefin Sans', cursive;
    }
    .kv-container h1:nth-of-type(2){
        padding-bottom: 5rem;
    }
    
    .kv-slider{
        margin: 0 auto;
        max-width: 95vw;
        max-height: 80vw;
        margin-top: 30px;
        margin-bottom: 50px;
    }
    
    .kv-slider img{
        max-height: 80vw;
        max-width: 100%;
        min-height: 80vw;
    }
    .slick-dots li button:before{
        color: white ;
        
    }

    
    .bottom-info{
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        justify-content: space-between;
        max-width: 80vw;    
    }
    .bottom-info-box{
        display: flex;
        flex-direction: column;
    }
    .bottom-info-box h3{
        color: #eb05a2;
        padding: 0;
        margin: 0;
        font-size: 2.9vw;
        margin-bottom: 3vw;
        margin-top: 22vw;
    }
    .bottom-info-box span{
        color: #c627e6;
        line-height: 1.5em;
        font-size: 2.95vw;
    }
    .bottom-info-box p {
        color: #eb05a2;
        padding-top: 20px;
        margin-bottom: 3vw;
        font-size: 2.9vw;
        font-weight: 700;
    }
    
    footer{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-width: 900px;
        margin-top: 4.9vw;
        padding: 2vw;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 3.9vw;
        font-size: 3vw;
        height: auto;
    
    }
    footer img{
        max-width: 35vw;
        max-height: 100px;
        width: 100%;
        height: auto;
    }
    footer .copy-right{
       margin-top: 30px;
       font-size: 3.5vw;
    }
    
    /* pricing page */
    .pricing-kv-container{
        display: flex;
        justify-content: left;
        width: 100vw;
        height: 50vw;
        background-image: url(/static/media/pricing-kv-img.418712a4.jpg);
        background: linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url(/static/media/pricing-kv-img.418712a4.jpg);
        background-size: cover;
        margin-top: 30px;
        margin-bottom: 60px;
        font-size: 11vw;
        color: #eb05a2;
        border-bottom: 1px solid grey;
    }
    .pricing-kv-container span{
      padding-left: 10vw;
      padding-bottom: 5.46vw;
      align-self: flex-end;
      white-space: pre-line;
    }
    
    .pricing-main-txt-container{
        max-width: 700px;
        margin: 0 auto;
        margin-bottom: 30px;
        padding: 5vw;
        padding-top: 0;
    }
    .pricing-kv-txt{
        color: #eb05a2;
        text-transform: uppercase;
        text-align: center;
    }
    .pricing-kv-txt h3{
        font-size: 4vw;
        margin: 10px 0;
    }
    .pricing-main-txt-container h4{
        color: #c627e6;
        font-size: 4vw;
        margin-bottom: 20px;
    }
    .pricing-main-txt-container p{
        color: white;
         font-size: 3vw;
    }
    .pricing-txt-bottom{
        max-width: 900px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0 auto;
        font-size: 1em;
        margin-top: 7.5vw;
        padding: 0 9vw;
    
    }
    .pricing-txt-bottom div{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .pricing-txt-bottom span{
        color: #eb05a2;
        font-size: 5.8vw;
        margin-bottom: 3vw;
    }
    .pricing-txt-bottom p{
        color: #c627e6;
        font-size: 4vw;
        text-align: left;
        line-height: 1.5;
    }
    
    .party-kv-container{
        margin-top: 1vw;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid grey;
    }
    .party-kv-img{
        width: 100vw;
        height: 50vw;
        background-image: url(/static/media/party-kv-img.668cfd39.jpg);
        background-position: 25% 40%;
        background-size:cover;
        background-repeat: no-repeat;
        padding: 0;
        margin: 0;
    }
    .party-kv-container span{
        
        display: flex;
        color: #eb05a2;
        font-size: 6vw;
        word-break: break-word;
        max-width: 15ch;
        text-transform: uppercase;
        margin-top: 6vw;
        margin-left: 6.75vw;
    }
    .party-txt-header{
        text-align: center;
        font-size: 5vw;
        max-width: 650px;
        margin: 40px auto;
        padding: 0 1.5vw;
    }
    .party-txt-header span{
        color: #c627e6;
    }
    .party-txt-header p{
        margin-top: 30px;
        margin-bottom: 4vw;
    }
    .party-txt{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        max-width: 900px;
        margin-top: 50px;
        padding: 0 4vw;
    }
    .party-txt ul{
        font-size: 3vw;
        line-height: 30px;
        padding-left: 2vw;
        padding-top: 2vw;
    }
    /* .party-txt ul li:nth-of-type(5){
        margin-top: 25px;
    } */
    .party-txt span{
        text-transform: uppercase;
        color: #c627e6;
        word-break: break-word;
        max-width: 10ch;
        font-size: 6vw;
    }
    
    
    /* footer social media */
    .social-media-links{
        display: flex;
        justify-content: space-evenly;
        min-width: 125px;
        margin-top: 30px;
        font-size: 4vw;
       
    }
    .social-media-links div{
        background-color: white; 
        border-radius: 10%; 
        display: flex; 
        width:12px; 
        height: 14px; 
        justify-content: center; 
        align-items: center; 
        margin-top: 2px;
    }
    .social-media-links div svg{
        color: rgb(233, 89, 80);
        font-size: 20px;
    }
    
    
    /* drinks page css */
    .drinks-kv-container{
        margin-top: 25px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column-reverse;
        border-bottom: rgb(107, 107, 107) 1px solid;
        border-top: 1px solid grey;
        padding-bottom: 8vw;

    }
    .drinks-kv-container span{
        margin-top: 25px;
        display: flex;
        color: #eb05a2;
        font-size: 11vw;
        word-break: break-word;
        max-width: 15ch;
        text-transform: uppercase;
        margin-left: 3vw;
        max-width: 300px;
    }
    .drinks-kv-img{
        width: 100%;
        height: 50vh;
        background-image: url(/static/media/drinks-kv-img.8ce24367.jpg);
        background-position: 25% 40%;
        background-size:cover;
        background-repeat: no-repeat;
        padding: 0;
        margin: 0;
    }
    
    .drink-txt-box{
        margin: 0 auto;
        margin-top: 30px;
        display: grid;
      /* grid-template-columns: 1fr 1fr 1fr; */
      grid-template-rows: 1fr 1fr 1fr;
      grid-gap: 20px;
      max-width: 900px;
      text-align: center;
      border-bottom: rgb(102, 102, 102) 1px solid;
    }
    
    .drinks-txt{
        margin-bottom: 1vw;
    }
    .drinks-txt span{
        color:#eb05a2;
        font-size: 7vw;
        font-weight: 400;
        
    }
    .drinks-txt p{
        color: white;
        font-size: 5vw;
        font-weight: 400;
        margin-top: 10px;
        margin-left: 5px;
    }
    
    /* events page css */
    .events-kv-container{
        margin-top: 25px;
        display: flex;
        justify-content: space-between;
        flex-direction: column-reverse;
        width: 100%;
        border-bottom: 1px solid grey;
        border-top: 1px solid grey;
    }
    .events-kv-container span{
        margin-top: 25px;
        display: flex;
        color: #eb05a2;
        font-size: 8vw;
        text-transform: uppercase;
        padding-left: 4vw;
        padding-bottom: 6vw;
        border-bottom: grey 1px solid;
        
        /* margin-left: 2.5rem; */
    }
    .events-kv-img{
        width: 100vw;
        height: 60vw;
        background-image: url(/static/media/events-kv-img.2816e8d3.jpg);
        background-position: 50% 30%;
        background-size:cover;
        background-repeat: no-repeat;
        padding: 0;
        margin: 0;
    }
    .events-txt-box{
        margin: 0 auto;
        margin-top: 30px;
        display: grid;
        /* grid-template-columns: 1fr 1fr 1fr; */
        grid-template-rows: 1fr 1fr ;
        grid-gap: 20px;
        max-width: 900px;
        text-align: center;
        border-bottom: rgb(102, 102, 102) 1px solid;
        padding-bottom: 6vw;
    }

    .events-txt{
        margin-top: 30px;
    }
    
    .events-txt span{
        color:#c627e6;
        font-size: 31px;
        font-weight: 400;
        
    }
    .events-txt p{
        color: white;
        font-size: 19px;
        font-weight: 400;
        margin-top: 20px;
        margin-left: 5px;
    }
    .facebook-conent-container{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 110vw;
        margin: 0 auto;
        
    }
    
    .bottom-event-txt{
        margin: 0 auto;
        padding: 4vw;
        text-align: center;
        margin-top: 2vw;
    }
    .bottom-event-txt h1{
        color: #eb05a2;
    }
    .bottom-event-txt h2{
        color:#c627e6;
        font-size: 19px;
        font-weight: 400;
    
    }
    
    /* about us page */
    .about-kv-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-bottom: 35px;
        border-bottom: 1px solid grey;
    }
    .about-kv-container img{
        width: 100vw;
        margin-bottom: 30px;
    }
    .about-title-container{
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }
    .about-title-container span:nth-of-type(1){
        color: #c627e6;
        margin: 0 auto;
        font-size: 8vw;
    
    }
    .about-title-container span:nth-of-type(2){
        color: #eb05a2;
        margin: 0 auto;
        font-size: 8vw;
    
    }
    .about-kv-container span{
        margin-top: 25px;
        display: flex;
        color: #eb05a2;
        font-size: 55px;
        text-transform: uppercase;
        margin-left: 2.5rem;
        
    }
    .about-kv-img{
        width: 100vw;
        height: 60vw;
        background-image: url(/static/media/about-kv-img.97820440.jpg);
        background-position: 50% 30%;
        background-size:cover;
        background-repeat: no-repeat;
        padding: 0;
        margin: 0;
        margin-bottom: 40px;
        border-top: grey 2px solid;
        border-bottom: grey 1px solid;
    }
    
    .about-txt{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 4vw;
        max-width: 900px;
        margin: 30px auto;
        margin-top: 0;
        padding-top: 30px;
        padding-bottom: 30px;
        border-top: grey 1px solid;
        border-bottom: grey 1px solid;
    }
    .about-txt span{
        color: #c627e6;
        font-size: 40px;
        font-weight: 400;
        margin: 0px 0px;
        margin-bottom: 20px;
        text-align: center;
    }
    .about-txt p{
        color: white;
        word-break: break-word;
        max-width: 60ch;
        font-size: 19px;
        font-weight: 400;
        padding: 10px 0px;
        line-height: 1.5;
    }
    .about-txt-flex-column{
        display: flex;
        flex-direction: column;
    }
    .about-txt-flex-column p{
        line-height: 1.5;
    }
    .about-txt-flex-column p:nth-of-type(2){
        margin-top: 10px;
    }
    
    .about-txt-bottom-kv{
        display: flex;
        justify-content: space-between;
        flex-direction: column-reverse;
        max-width: 75vw;
        margin: 0 auto;
        margin-top: 150px;
    }
    .nelsons-story-container{
        display: flex;
        flex-direction: column;
        padding-top: 60px;
    }
    .nelsons-story-container span{
        color: #eb05a2;
        font-size: 31px;
    }
    .nelsons-story-container p{
        margin: 20px 0;
        word-break: break-word;
        max-width: 45ch;
        font-size: 19px;
        line-height: 27px;
    }
    .about-bottom-txt-kv-img-container{
        width: 50vw;
        height: 55vw;
        background-image: url(/static/media/about-bottom-kv-img.ab5f0284.jpg);
        background-position: 50% 30%;
        background-size:cover;
        background-repeat: no-repeat;
        padding: 0;
        margin: 0;
    }
    
    /* Gift Card Page */
    
    .gift-cards-container{
        background-color: white;
        color: black;
        margin: 0;
        padding: 0;
        width: 100vw;
        height: auto;
    }
    
    .gift-cards-container nav{
        max-width: 100vw;
        text-align: center;
    
       
        position: -webkit-sticky;
    
       
        position: sticky;
        top: 0;
        width: 100%;
        background-color: white;
        z-index: 999;
    
        padding-top: 20px;
        border-bottom: 2px solid grey;
        padding-bottom: 30px;
    }
    .item-1 {
        /* grid-row-start: 1;
        grid-row-end: 3; */
        grid-column-start: 2;
        grid-column-end: 3;
      }
    .gift-cards-container nav span{
        color: white;
    }
    .gift-cards-container nav a p:first-child{
        margin-top: 15px;
    }
    
    .gift-cards-container nav img{
        max-width: 75px;
        max-height: 30px ;
    }
    
    .gift-cards-container .background-color-container{
        background-color: #556b7c;
        color: white;
        margin-bottom: 5rem;
    }
    
    .background-color-container{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .background-color-container span{
        font-size: 8vw;
        font-weight: 200;
        margin-top: 30px;
       }
    .background-color-container p{
        font-size: 3.5vw;
        margin-top: 1rem;
    }
    .gift-card-img-container{
        max-width: 220px;
        width: 100%;
        height: 150px;
        border: 5px solid white;
        border-radius: 3%;
        box-shadow: 0 4px 2px -2px rgb(177, 177, 177);
        background-color: white;
        background-image: url(/static/media/gift-card-kv-img.9ba3bd0c.png);
        background-position: 50% 30%;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 0;
        margin: 0;
        position: relative;
        top: 40px;
    }
    /* form css */
    .payment-container{
        max-width: 320px;
        padding: 40px;
        padding-top: 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: 0 auto;
    }
    .payment-container input{
        font-size: 11px;
    }
    .payment-container label{
        font-size: 12px;
        font-weight: bold;
        
        letter-spacing: .65px;
        color: #43494f;
        margin-top: 20px;
        margin-bottom: 10px;
    }
    .payment-container .price-buttons{
        font-size: 15px;
        font-stretch: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
    }
    .price-buttons{
        color: #64696E;
    }
    .payment-container .price-buttons span{
        font-weight: bold;
        width: 80vw;
        display: flex;
        flex-direction: column;
    }
    
    .payment-container .price-buttons span:nth-child(5){
        border-right: none;
    }
    .list-items{
        display: flex;
        /* justify-content: space-between; */
        align-items:center;
        /* border: 1px solid #d5d9dc; */
        border-radius: 3px;
        /* margin-top: 300px;
        margin-bottom: 200px; */
    }
    .list-items b{
        padding-left: 15px;
        padding-right: 15px;
    }
    .list-items div{
        height: 25px;
        width: 100%;
        text-align: center;
        border-top: 1px rgb(213, 217, 220) solid;
        border-left: 1px rgb(213, 217, 220) solid;
        border-right: 1px rgb(213, 217, 220) solid;
        padding-top: 10px;
    }
    .list-items div:nth-of-type(1){

    }
    .list-items div:nth-of-type(5){
        /* width: 100%; */
        border-bottom: 1px rgb(213, 217, 220) solid;
    }
    .custom-button{
        border: 1px solid rgb(213, 217, 220);
        border-radius: 3px;
        height: 25px;
        border-right: 1px rgb(213, 217, 220) solid;
        
        padding-top: 10px;
    }
    /* conditional class on price buttons */
    .item-list{
        background-color: white;
    }
    .price-selected{
        color: white;
        background-color: black;
    }
    .payment-container input{
        margin-top: 5px;
        height: 30px;
        border: 1px rgb(213, 217, 220) solid;
        margin-bottom: 5px;
    }
    .inputWithButtton{
        position: relative;
        height: 100px;
    }
    .promo-code-input{
        padding-right: 30px;
    }
    .promo-code-button{
        background-color: white;
        border: none;
        font-size: 13px;
        color: #2996cc;
        width: 12%;
        margin-left: auto;
        margin-right: 6vw;
        position: relative;
        bottom: 31px
    }
    .promo-code-button:hover{
        cursor: pointer;
    }
    .user-form-flexbox{
        display: flex;
        justify-content: space-between;
    }
    .user-form-flexbox article{
        display: flex;
        
    }
    .user-form-flexbox article input{
        width: 96%;
        height: 30px;
    }
    .user-form-flexbox .error-message{
        color: red;
        font-size: 14px;
    }
    .checkbox-container{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-right: auto;
        -webkit-margin-before: 0px;
                margin-block-start: 0px;
        -webkit-margin-after: 0px;
                margin-block-end: 0px;
        -webkit-margin-start: 0px;
                margin-inline-start: 0px;
        -webkit-margin-end: 0px;
                margin-inline-end: 0px;
        margin-top: 10px;
    }
    .checkbox-container input {
        height: 24px;
        width: 24px;
    }
    .checkbox-container span {
        font-size: 12px;
        line-height: 20px;
    }
    
    .recipient-form-flexbox{
        display: flex;
        justify-content: space-between;
    }
    .recipient-form-flexbox aside{
        width: 49%;
        
    }
    .recipient-form-flexbox aside input{
        width: 96%;
        height: 30px;
    }
    
    .payment-container textarea {
        border: 1px rgb(195, 198, 201) solid;
    }
    .payment-container .personal-msg-txt u{
        color: rgb(195, 198, 201);
        text-decoration: none;
    }
    
    .delivery-buttons-flexbox{
        display: flex;
        align-items: center;
    }
    .delivery-buttons-flexbox aside{
        width: 50%;
        text-align: center;
        
    }
    .delivery-buttons-flexbox aside div{
        height: 30px;
        padding-top: 15px;
        border-radius: 2px;
        border: 1px solid rgb(213, 217, 220);
        font-size: 14px;
        font-weight: bold;
    }
    .delivery-buttons-flexbox aside div:hover{
        cursor: pointer;
    }
    .delivery-buttons-flexbox .delivery-active{
        background-color: #32373c;
        color: white;
        font-size: 12px;
        
    align-items: center;
    }
    .delivery-buttons-flexbox .delivery{
        color: #32373c !important;
        background-color: white !important;
        font-size: 12px;
        
    align-items: center;
    }
    .calendar{
        display: flex;
        justify-content: center;
        margin-top: 1vw;
    }
    .checkout-button{
        width: 100%;
        height: 30px;
        padding-top: 15px;
        padding-bottom: 30px;
        margin-top: 15px;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 1vw;
        border: none;
        text-align: center;
        background-color: #006edce6;
        color: white;
        font-weight: bolder;
        font-size: 16px;
    }
    
    .checkout-button:hover{
        cursor: pointer;
    }
    
    /* checkoutpage css */
    .checkout-container{
        background-color: white !important;
        color: black;
        margin: 0;
        padding: 0;
        max-width: 1920px;
        height: auto;
    }
    
    .checkout-container nav{
        max-width: 100vw;
    
        /* display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        grid-gap: 5px; */
        text-align: center;
    
       
        position: -webkit-sticky;
    
       
        position: sticky;
        top: 0;
        width: 100%;
        background-color: white;
        z-index: 999;
    
        padding-top: 20px;
        border-bottom: 2px solid grey;
        padding-bottom: 30px;
    }
    .item-1 {
        /* grid-row-start: 1;
        grid-row-end: 3; */
        grid-column-start: 2;
        grid-column-end: 3;
      }
    .checkout-container nav span{
        color: white;
    }
    
    .checkout-container nav img{
        max-width: 75px;
        max-height: 30px ;
    }
    
    
    .checkout-container .checkout-title h1, h2{
        text-align: center;
        margin-bottom: 10px;
    }
    .checkout-container .checkout-title h1{
        font-size: 10vw;
        font-weight: normal;
        color: #43494f;
    }
    .checkout-container .checkout-title h2{
        font-size: 5vw;
        font-weight: normal;
        color: #43494f;
    }
    
    .checkout-content-container{
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
        padding-bottom: 50px;
    }
    
    .checkout-content-container .stripe-js-content-container{
    /* margin-right: 30px; */
        padding: 4vw;
        margin-top: 25px;
        max-width: 500px;
    }
    
    .digital-wallet-divder {
        text-align: center; 
        }
        
        .divider-line {
            height: 1px;
            background: rgb(189, 189, 189);
            max-width: 500px;
        }
        
        .digital-wallet-divder  h2 {
            position: relative;
            bottom: 34px;
            background: #fff;
            display: inline-block;
            padding: 0 20px;
           
        }
    
        .payment-inputs__container .jnYdMq{
            width: 100%;
            margin-bottom: 30px;
        }
        .payment-inputs__container-error .jnYdMq{
            border: 2px solid red;
            border-radius: 5px;
        }
        .payment-warning p{
            margin-bottom: 30px;
            line-height: 24px;
            font-size: 4vw;
        }
        .payment-warning b {
            color: #2996cc;
            font-weight: normal;
        }
        .payment-warning b:hover{
            cursor: pointer;
        }
    .payment-submit-button{
            background-color: #006aff;
            margin-top: 20px;
            margin-bottom: 20px;
            color: white;
            width: 100%;
            padding-top: 20px;
            padding-bottom: 20px;
            font-weight: bolder;
            font-size: 18px;
            border: none;
            border-radius: 5px;
    }
    .payment-submit-button:hover {
            cursor: pointer;
        }
    
    /* order details  */
    .order-details__container{
       height: auto;
       padding: 3vw 0;
       padding-right: 5vw;
       max-width: 260px;
    }
    .order-details__container .order-details__top-content{
        display: flex;
        justify-content: space-between;
        min-width: 282px;
    }
    .order-details__container .order-details__top-content b{
        font-weight: normal;
        color: #006aff;
    }
    .order-details__container .order-details__top-content b:hover{
        cursor: pointer;
    }
    .order-details__container .order-details__top-content span{
        font-weight: 500;
        letter-spacing: 0.05em;
    }
     .gift-card-payment-img-container {
        
        width: 100%;
        height: 200px;
        background-color: white;
        background-image: url(/static/media/gift-card-kv-img.9ba3bd0c.png);
        background-position: 50% 30%;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 0;
        margin: 0 auto;
        
    }
    .order-details__container .order-details__top-element-border{
        width: 100%;
        padding: 3vw;
        border: rgb(190, 190, 190) 1px solid;
        border-radius: 3px;
    }
    .order-details__container .order-details__content{
        width: 100%;
        border: rgb(190, 190, 190) 1px solid;
        border-top: none;
        border-radius: 3px;
        
        padding: 3vw;
        padding-top: 10px;
    }
    .order-details__container .price-date__flex{
        display: flex;
        justify-content: space-between;
    }
    .order-details__container .price-date__flex h1{
        font-size: 36px;
        padding: 0;
        margin: 0;
        color: #43494f;
        margin-top: 10px;
    }
    .order-details__container .price-date__flex h2{
        font-size: 18px;
        padding: 0;
        color: #777c81;
        
    }
    .payment-warning{
        line-height: 1.5;
    }
    .order-details__container .amount-pay__container{
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: #006aff;
        padding-bottom: 10px;
        border-bottom: 6px solid #f8f4f4;
        margin-bottom: 20px;
    }
    .order-details__container .amount-pay__container h3, h4{
        margin-bottom: 0;
        padding: 0;
    }
    .order-details__container .amount-pay__container h4{
        font-size: 20px;
        margin-top: 17px;
    }
    .order-details__container .recipient-credentials{
        margin-bottom: 15px;
    }
    .order-details__container .recipient-credentials p:nth-child(1){
        margin-bottom: 10px;
        font-weight: 550;
        color: #777c81;
        line-height: 20px;
    }
    .order-details__container .recipient-credentials p:nth-child(2){
        color: #777c81;
        font-weight: 300;
        
    }
    
    .order-details__container .location-tag{
        word-wrap: break-word;
        font-weight: 550;
        color: #777c81;
        text-align: left;
        width: 32ch;
        line-height: 26px;
    }
    
    /* auto email css */
    .auto-email-form{
        display: flex;
        flex-direction: column;
    }
    .auto-email-form label, input{
        margin-bottom: 10px;
    }
    
    .auto-email-form input{
        min-height: 25px;
        border-radius: 5px;
        border: rgb(190, 190, 190) 1px solid;
    }
    .auto-email-form textarea{
        border-radius: 5px;
    
        border: rgb(190, 190, 190) 1px solid;
    }
    .auto-email-form .hidden-input{
        visibility: hidden;
        outline: none;
        border: none;
    }    
  }


.kv-icons-wrapper{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
}
.kv-icons-wrapper article{
    margin: 0 10vw;
}
.kv-icons-wrapper article span svg{
    padding: 0 15px;
    color: #c627e6;
    height: 100%;
    width: 100%;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
}

.header-kv-img-container {
    width: 30vw;
    height: auto;
    display: flex;
    align-items:baseline;
    justify-content: flex-start;
}
.header-kv-img-container section{
    display: flex;
    align-items: baseline;
}
.header-kv-img-container aside{
    justify-content: center;
}

.header-kv-img-container img{
    max-width: 300px;
    max-height: 105px;
    width: 100%;
    height: auto;
}
nav{
max-width: 75vw;
margin: 0 auto;
padding: 8px 0;
}

header nav{
    display: flex;
    justify-content:space-evenly;
    align-items: center;

}
header nav a{
    text-decoration: none;
    font-size: 1.5vw;
    color: #eb05a2;
}

@media only screen and (max-width: 799px) {
    header nav a {
     display: none;
    }
    .sidebar{
        min-width: 20.8vw;
    }
    .kv-icons-wrapper{
        display: flex;
        width: 100%;
        padding-bottom: 1vw;
    }
    .kv-icons-wrapper svg{
        font-size: 1.5vw;
    }
  }
